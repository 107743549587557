<template>
  <!-- Table Container Card -->
  <b-card no-body class="p-3">
    <validation-observer ref="formRef">
      <b-form @submit.prevent>

        <b-table
          id="refFormTable"
          ref="refFormTable"
          fixed
          sticky-header
          no-provider-paging
          no-provider-filtering
          :fields="tableColumns"
          :items="form"
          primary-key="id"
          show-empty
          class="position-relative"
          style="max-height: 400px"
        >
          <!-- Custom Header Column -->
          <template #head(actions)>
            <feather-icon
              icon="MoreVerticalIcon"
              class="mx-auto"
            />
          </template>

          <!-- Column: Description -->
          <template #cell(prefix)="{ index }">
            <div>
              <b-row :key="formulaIdx" v-for="(formula, formulaIdx) in form[index].formulas">
                <b-col md="4">
                  <b-form-group
                    label="Type"
                    :label-for="`type${index}`"
                  >
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="type"
                      rules="required"
                    > -->
                      <v-select
                        :id="`type${index}`"
                        v-model="form[index].formulas[formulaIdx].type"
                        :reduce="field => field.code"
                        :options="LOV.types"
                        class="select-size-sm"
                        @option:selected="() => form[index].formulas[formulaIdx].code = ''"
                      />
                      <!-- <small class="text-danger">{{ errors[0]}}</small> -->
                    <!-- </validation-provider> -->
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group 
                    v-if="['Prefix', '#'].includes(form[index].formulas[formulaIdx].type)"
                    :label="form[index].formulas[formulaIdx].type === 'Prefix' ? $t('apps.masterDataPrefix.singular.prefix') : 'Running Number'"
                    :label-for="`prefix${index}`">
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="prefix"
                      rules="required"
                    > -->
                      <b-form-input
                        :disabled="form[index].formulas[formulaIdx].type === '#'"
                        :placeholder="form[index].formulas[formulaIdx].type === '#' ? 'Generated by System' : ''"
                        :id="`prefix${index}`"
                        v-model="form[index].formulas[formulaIdx].code"
                        size="sm"
                      />
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <!-- </validation-provider> -->
                  </b-form-group>
                  <b-form-group
                    v-else-if="['Prefix', 'Year','Prefix', 'Month'].includes(form[index].formulas[formulaIdx].type)"
                    label="Detail Type"
                    :label-for="`prefix${index}`">
                    <v-select
                      :id="`type${index}`"
                      v-model="form[index].formulas[formulaIdx].code"
                      :reduce="field => field.code"
                      :options="form[index].formulas[formulaIdx].type === 'Month' ? LOV.typeMonths : LOV.typeYears"
                      class="select-size-sm"
                  />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    v-if="formulaIdx !== form[index].formulas.length - 1" 
                    label="Separator">
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="separator"
                      rules="required"
                    > -->
                      <b-form-input
                        :id="`separator${index}`"
                        v-model="form[index].formulas[formulaIdx].separator"
                        size="sm"
                      />
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <!-- </validation-provider> -->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button variant="outline-primary" size="sm" @click="addNewFormula(index)" :disabled="form[index].formulas.length ===  LOV.types.length">
                <feather-icon
                  icon="PlusIcon"
                />
                <!-- {{ $t('globalActions.update') }} -->
              </b-button>
            </div>
          </template>
          
          <!-- Column: Description -->
          <template #cell(separator)="{ index }">
            <div class="d-flex justify-content-between">
              <b-form-group
                label="Type"
                :label-for="`type${index}`"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  rules="required"
                >
                  <v-select
                    :id="`type${index}`"
                    ref="firstFocusSelectInput"
                    v-model="type"
                    :options="LOV.types"
                    :disabled="actions.isPreview"
                  />
                  <small class="text-danger">{{ errors[0]}}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="prefix"
                  rules="required"
                >
                  <b-form-input
                    :id="`separator-${index}`"
                    v-model="form[index].prefix"
                    size="sm"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </template>

        </b-table>

        <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-end">
          <b-button
            variant="primary"
            type="submit"
            @click="handleSubmit"
          >
            <feather-icon
              icon="SaveIcon"
            />
            {{ $t('globalActions.update') }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BTable
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
    ValidationProvider,
    ValidationObserver
  },
  setup () {
    const form = ref([])

    const {
      toast,
      $put,
      $get
    } = useHttp()

    const type = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'transactionType', label: 'Transaction Type' },
      { key: 'prefix', label: 'Code' },
      // { key: 'separator', label: 'Separator' }
    ]

    const LOV = ref({
      types: [
        { code: 'Prefix', label: 'Prefix'},
        { code: 'Month', label: 'Month'},
        { code: 'Year', label: 'Year'},
        { code: 'Branch', label: 'Branch'},
        { code: 'Office', label: 'Office'},
        { code: '#', label: '# - Running number'},
      ],
      // type of months and year are based on moment js formatter 
      // https://momentjs.com/docs/#/displaying/format/
      typeMonths: [
        { code: 'M', label: 'M (Ex: 1)'},
        { code: 'MM', label: 'MM (Ex: 01)'},
        { code: 'MMM', label: 'Mon (Ex: Jan)'},
        { code: 'MMMM', label: 'Month (Ex: January)'}
      ],
      typeYears: [
        { code: 'YY', label: 'YY (Ex: 22)'},
        { code: 'YYYY', label: 'YY (Ex: 2022)'}
      ]
    })

    const getPrefixes = async () => {
      const prefixes = await $get({
        url: 'master/prefix'
      })

      prefixes.data.map(prefix => {
        form.value.push({
          id: prefix.id,
          transactionType: prefix.transactionType.remark_eng,
          prefix: prefix.prefix,
          formulas: prefix.formulas.length > 0 ? prefix.formulas : [{type: 'Prefix', code: prefix.prefix, separator: ''}, {type: '#', code: '', separator: ''}]
        })
      })
    }

    const addNewFormula = (formIdx) => {
      form.value[formIdx].formulas.push({
        type: '', 
        code: '', 
        separator: ''
      })
    }

    onMounted(() => {
      getPrefixes()
    })

    return {
      addNewFormula,
      type,
      LOV,
      form,
      tableColumns,
      required,
      toast,
      $put
    }
  },
  methods: {
    handleSubmit () {
      const $swal = this.$swal

      $swal({
        title: `${ this.$t('confirm.updateThis') } ${ this.$t('apps.masterDataPrefix.singular.prefix') } ?`,
        text: `${ this.$t('info.ofCourse') } you still can delete or edit later.`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async ({ value }) => {
        if (value) {
          const callbackSuccess = () => {
            this.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: `${this.$t('feedback.success')} ${this.$t('feedback.updated')} ${this.$t('apps.masterDataPrefix.singular.prefix')}.`
              }
            })
          }

          this.$put({
            data: this.form,
            url: 'master/prefix'
          }).then(() => callbackSuccess())
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
